import React from "react"

import { graphql } from "gatsby"
import styled from "styled-components"

import Container from "components/container"
import Hero from "components/hero"
import Layout from "components/layout"
import SeparatorH2Style from "components/separator-h2-style"

const VersionNumber = styled.h3`
  font-weight: 500;
  font-size: 19px;
`

const VersionLinkWrapper = styled.div`
  margin-top: 10px;
  display: inline-block;
  > a {
    margin-left: 10px;
    font-weight: 500;
  }
  > a:first-child {
    margin-left: 0px;
  }
`

const legalPolicyTemplate = ({ data }) => {
  const allPolicies = data.allPolicies.nodes
  const thisPolicy = data.policy
  const version = thisPolicy.frontmatter.version

  return (
    <Layout
      title={thisPolicy.frontmatter.kind}
      description={thisPolicy.excerpt}
    >
      <Hero title={thisPolicy.frontmatter.kind}>
        <VersionNumber>
          Version {version} – {thisPolicy.frontmatter.date}{" "}
          {thisPolicy.frontmatter.latest && "(latest)"}
        </VersionNumber>
        <VersionLinkWrapper>
          {allPolicies.map((policy) => {
            if (policy.id != thisPolicy.id) {
              return (
                <a
                  href={policy.fields.path}
                  title={`Go to Version ${policy.frontmatter.version}`}
                >
                  Version {policy.frontmatter.version}{" "}
                  {policy.frontmatter.latest && "(latest)"}
                </a>
              )
            }
          })}
        </VersionLinkWrapper>
      </Hero>
      <Container size="narrow">
        <SeparatorH2Style>
          <div dangerouslySetInnerHTML={{ __html: thisPolicy.html }} />
        </SeparatorH2Style>
      </Container>
    </Layout>
  )
}

export default legalPolicyTemplate

export const query = graphql`
  query ($id: String!, $kind: String!) {
    site {
      siteMetadata {
        title
      }
    }
    policy: markdownRemark(id: { eq: $id }) {
      excerpt
      frontmatter {
        kind
        version
        latest
        date(formatString: "D MMMM YYYY")
      }
      html
      id
    }
    allPolicies: allMarkdownRemark(
      filter: { frontmatter: { kind: { eq: $kind } } }
      sort: { order: DESC, fields: frontmatter___version }
    ) {
      nodes {
        frontmatter {
          version
          latest
        }
        fields {
          path
        }
        id
      }
    }
  }
`
